import React from "react";
import "./AboutPage.css"; // Make sure the path to your CSS file is correct

const DescriptionSection = () => {
  return (
    <section className="description-section">
      <div className="container">
        <div className="description__inner">
          <p>
            Мы создали платформу, где покупатели, в лице поваров и категорийных
            менеджеров, могут найти все необходимые товары и сократить время на
            оформление заказа, а поставщики оптимизировать операционные процессы
            и находить новых клиентов.
          </p>
          <p>
            Ранее мы сами работали с ресторанами в качестве поставщика свежих
            овощей и фруктов и поняли какие существуют потребности и проблемы у
            каждой из сторон.
          </p>
          <p>
            Во-первых, су-шеф в конце своей 15-часовой смены хочет быстро и
            безошибочно оформить заказы у своих поставщиков, количество которых
            у крупных ресторанов может достигать 20-30, НО текущий способ
            оформления по телефону и в WhatsApp не удобен, занимает много
            времени и история легко теряется.
          </p>
          <p>
            Во-вторых, шеф-повар хочет работать с качественными поставщиками с
            точки зрения соотношения цены и качества, НО не знает всех
            поставщиков на рынке и не имеет возможности быстро с ними связаться
            и получить индивидуальные ценовые предложения. В итоге вынужден
            где-то мириться с несответствующим качеством, высокой ценой или
            низким уровнем сервиса.
          </p>
          <p>
            На стороне производителей и дистрибьюторов: <br />
            Компании хотят найти эффективный канал привлечения новых клиентов,
            НО ранее у них не было специализированной, удобной площадки для
            взаимодействия с их целевой аудиторией из HoReCa.
          </p>
          <p>
            Так же руководители компаний хотят улучшить операционные процессы,
            сократить издержки и ошибки, НО сейчас оформление заказов происходит
            вручную, допускаются ошибки и менеджеры вместо того, чтобы
            заниматься развитием бизнеса тратят большую часть времени на
            рутинные задачи.
          </p>
          <p
            className="description-bold-text"
            style={{
              fontWeight: 600,
              cursor: "pointer",
            }}
          >
            {" "}
            Наша цель помочь клиентам и партнерам-поставщикам решить их "боли" и
            способствовать успешному развитию их бизнеса!
          </p>
        </div>
      </div>
    </section>
  );
};

export default DescriptionSection;
